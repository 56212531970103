.cal-month-view .cal-event {
  background-color: #1e90ff;
  border-color: #d1e8ff;
  width: 100%;
  height: 20px;
  color: #fff;
  border-radius: 0;
}

.cal-month-view .cal-day-badge {
  display: none;
}

.cal-month-view .cal-open-day-events {
  background-color: #fff7e6;
  color: #001529;
  box-shadow: none;
}
