/* You can add global styles to this file, and also import other style files */
@import "../node_modules/angular-calendar/css/angular-calendar.css";
@import "src/app/core/theme/calendar.theme";
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';
@import '~codemirror/lib/codemirror.css';
@import '~codemirror/theme/idea.css';
@import '~codemirror/addon/fold/foldgutter.css';
@import '~codemirror/addon/lint/lint.css';

.content {
  flex: 1;
}

.content p {
  margin-bottom: 1em;
}

.content-link a {
  margin-right: 16px;
}

.content-link a img {
  margin-right: 8px;
}

.pagination {
  display: flex;
  justify-content: flex-end;
}


.content-image {
  margin: 0 0 0 60px;
  display: flex;
  align-items: center;
}

.active-interval {
  background: #ffeadc;
}


.content-image img {
  width: 100%;
}

app-statistic-dashboard .ant-card-body {
  text-align: center;
}

app-device-configuration-tools .ant-collapse-header {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 24px;
}

app-device-configuration-tools .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  font-size: 20px;
}

@media (max-width: 768px) {
  .content-image {
    flex: 100%;
    margin: 24px 0 0;
  }
}

.edit-btn {
  background: #fa822c;
  border: none;
  color: white;
  border-radius: 2px;
}

.delete-btn {
  background: #cb3636;
  border: none;
  color: white;
  border-radius: 2px;
}

.actions-coll {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.ant-pagination {
  margin-top: 4px;
}

.ant-timeline-item {
  margin-left: 10px;
}

.ant-timeline {
  margin-top: 5px;
}

.nz-page-header {
  background-color: white;
}

.content-setting {
  margin-top: 16px;
  background-color: white;
  height: 100vh;
  width: 100%;
}

.content-setting-data {
  padding: 16px;

}

.form-item {
  margin-bottom: 2rem;
}

div.company-form-modal {
  width: 90% !important;
  max-width: 1000px;

  div.ant-modal-footer {
    text-align: center;
  }

  @media (max-width: 576px) {
    width: 100% !important;
  }
}

.fullscreen-control-button {
  border: 2px solid rgba(0,0,0,0.2);
  background-color: #fff;
  padding: 5px 7px;
  border-radius: 2px;
  cursor:pointer;
}

.leaflet-popup-content{
  max-height: 250px;
  overflow: auto;
}

.ant-descriptions-row > th, .ant-descriptions-row > td {
  padding-bottom: 0;
}
